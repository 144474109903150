import ContentClass from './assets/scss/content.module.scss';
import ContentList from './components/contentList';
import Header from './components/header';
import Footer from './components/footer';
import StoreDesc from './components/page/storeDesc';
import Err from './components/page/Err';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';

const App = () => {
  return (
    <div className="App">
      <Header />
      <div className={ContentClass.content}>
        <div className={ContentClass.content__inner}>
          <BrowserRouter>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={ContentList} />
              <Route path="/store:id" component={StoreDesc} />
              <Route component={Err} />
            </Switch>
          </BrowserRouter>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
