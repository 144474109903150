import React from 'react';
import styles from '../assets/scss/footer.module.scss';

const Footer = React.memo(() => {
  return (
    <footer className={styles.footer}>
      <p className={styles.footerCopyRight}>
        <small>Powered by Google Maps Platform</small>
      </p>
      <p className={styles.footerCopyRight}>
        <small>Copyright &copy; 2021 Take Out Store. All Rights Reserved.</small>
      </p>
    </footer>
  );
});

export default Footer;
