import styles from '../assets/scss/store.module.scss';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const Store = React.memo((props) => {
  const { name, opening_hours, rating, vicinity, price_level, picture, icon } = props.store;
  const [priceStart, setPriceStar] = useState('');
  const [rateStar, setRateStar] = useState('');
  const pattern = /^https:\/\/maps\.googleapis\.com\/maps\/api\/place\/photo\?maxwidth=400/g;
  let id = props.id + 1;

  const to = {
    pathname: `/store${id}`,
    state: {
      store: props.store,
      priceStar: priceStart,
      rateStar: rateStar,
      type: props.type,
      range: props.range,
      scroll: props.scroll,
      stores: props.stores,
    },
  };

  useEffect(() => {
    switch (true) {
      case 0 <= rating && rating <= 1:
        setRateStar('★');
        break;
      case 1 < rating && rating <= 2:
        setRateStar('★★');
        break;
      case 2 < rating && rating <= 3:
        setRateStar('★★★');
        break;
      case 3 < rating && rating <= 4:
        setRateStar('★★★★');
        break;
      case 4 < rating && rating <= 5:
        setRateStar('★★★★★');
        break;
      default:
        setRateStar('評価なし');
        break;
    }
  }, [setRateStar, rating]);

  useEffect(() => {
    switch (true) {
      case 0 <= price_level && price_level <= 1:
        setPriceStar('安い');
        break;
      case 1 < price_level && price_level <= 2:
        setPriceStar('標準');
        break;
      case 2 < price_level && price_level <= 3:
        setPriceStar('高い');
        break;
      case 3 < price_level && price_level <= 4:
        setPriceStar('とても高い');
        break;
      default:
        setPriceStar('データなし');
        break;
    }
  }, [setPriceStar, price_level]);

  return (
    <li className={styles.store}>
      <Link to={to} store={props.store} className={styles.storeLink}>
        <h2 className={styles.storeName}>{name}</h2>
        <div className={styles.storeBody}>
          <figure>
            {picture.match(pattern) ? (
              <img src={icon} alt="店のロゴマーク" style={{ objectFit: 'contain' }} />
            ) : (
              <img src={picture} alt="店のロゴマーク" />
            )}
          </figure>
          <div className={styles.storeText}>
            <p>
              <span>評価（★1~5）：</span>
              {rateStar}
            </p>
            <p>
              <span>価格：</span>
              {priceStart}
            </p>
            <p>
              <span>住所：</span>
              {vicinity}
            </p>
            <p>
              <span>営業：</span>
              {opening_hours && opening_hours.open_now ? '営業中' : '準備中'}
            </p>
          </div>
        </div>
      </Link>
    </li>
  );
});

export default Store;
