import styles from '../assets/scss/storeList.module.scss';
import Store from './store';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import buttonStyles from '../assets/scss/button.module.scss';
import loadingStyles from '../assets/scss/loading.module.scss';
import nomessageStyles from '../assets/scss/nomessage.module.scss';
import contentStyles from '../assets/scss/content.module.scss';
import selectStyles from '../assets/scss/select.module.scss';
import { useLocation } from 'react-router-dom';

const ContentList = React.memo(() => {
  const [stores, setStores] = useState([]);
  const [positon, setPosition] = useState({});
  const [loading, setloading] = useState('');
  const [nomessage, setNomessage] = useState(false);
  const [range, setRange] = useState(500);
  const [type, setType] = useState('meal_takeaway');
  const [scrollPosition, setScrollPosition] = useState(0);
  const { state, selectedType, selectedRange, scroll } = useLocation();

  useEffect(() => {
    if (scroll) {
      setTimeout(() => window.scrollTo(0, scroll), 0);
    }
  }, [scroll]);

  useEffect(() => {
    getPosition();
    window.addEventListener('scroll', getScrollPosition);
    return () => {
      window.removeEventListener('scroll', getScrollPosition);
    };
  }, []);

  useEffect(() => {
    state && setStores(state);
    selectedType && setType(selectedType);
    selectedRange && setRange(selectedRange);
  }, [state, selectedType, selectedRange]);

  const getScrollPosition = () => {
    let scrollPos = window.scrollY;
    setScrollPosition(scrollPos);
  };

  const hideLoading = () => {
    setloading('');
  };

  const isLoading = () => {
    const loadingMessage = '読込中です...';
    setloading(loadingMessage);
  };

  const handleSelect = useCallback(
    (e) => {
      let val = e.target.value;
      setRange(val);
    },
    [setRange]
  );

  const handleType = useCallback(
    (e) => {
      let val = e.target.value;
      setType(val);
    },
    [setType]
  );

  const handleNoMessage = (shops) => {
    shops.length === 0 ? setNomessage(true) : setNomessage(false);
  };

  const getPosition = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setPosition(pos.coords);
        },
        (err) => {
          console.log(err);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 1000,
        }
      );
    }
  };

  const fetchStores = () => {
    let params = new URLSearchParams();
    params.append('lat', positon.latitude);
    params.append('lng', positon.longitude);
    params.append('range', range);
    params.append('type', type);

    axios
      .post('api.php', params)
      .then((res) => {
        let shops = res.data;
        setStores(shops);
        hideLoading();
        handleNoMessage(shops);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchStores = async () => {
    isLoading();
    await getPosition();
    fetchStores();
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {loading && <div className={loadingStyles.loader}>{loading}</div>}
      {nomessage && <p className={nomessageStyles.nomessage}>お店が見つかりませんでした。</p>}
      <ul className={styles.storeList}>
        {stores &&
          stores.map((store, key) => (
            <Store key={key} id={key} store={store} stores={stores} type={type} range={range} scroll={scrollPosition} />
          ))}
      </ul>
      <div className={buttonStyles.wrapper}>
        <form className={selectStyles.storeSelect}>
          <div>
            <label htmlFor="type">探したいお店</label>
            <select name="type" id="type" value={type} onChange={handleType}>
              <option value="meal_takeaway">テイクアウト</option>
              <option value="meal_delivery">デリバリー</option>
              <option value="restaurant">レストラン</option>
              <option value="cafe">カフェ</option>
            </select>
          </div>
          <div>
            <label htmlFor="range">対象範囲を選択</label>
            <select name="range" id="range" value={range} onChange={handleSelect}>
              <option value="500">500m</option>
              <option value="1000">1km</option>
              <option value="3000">3km</option>
            </select>
          </div>
        </form>
        <button type="button" onClick={searchStores} className={buttonStyles.primaryButton}>
          現在地から探す
        </button>
        <p className={contentStyles.posMessage}>
          ※ 位置情報の取得を許可して下さい
          <br />
          （取得した位置情報は店舗検索にのみ使用されます）
        </p>
      </div>
    </div>
  );
});

export default ContentList;
