import LinkButton from '../linkButton';
import styles from '../../assets/scss/err.module.scss';

const Err = () => {
  return (
    <div>
      <h2 className={styles.title}>404 Not Found</h2>
      <p className={styles.text}>お探しのページは見つかりませんでした。</p>
      <LinkButton to={{ pathname: '/' }}>トップへ</LinkButton>
    </div>
  );
};

export default Err;
